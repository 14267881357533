export default {
  setVisibleAndEnabled(state, profiles) {
    state.menuItems.forEach((menuItem) => {
      switch (menuItem.id) {
        case 1:
          menuItem.enabled = profiles.includes(1) || profiles.includes(11);
          break;
        case 2:
          menuItem.enabled =
            profiles.includes(2) || profiles.includes(13) || profiles.includes(4) || profiles.includes(6);
          break;
        case 6:
          menuItem.enabled = profiles.includes(3) || profiles.includes(5) || profiles.includes(12);
          break;
        default:
          break;
      }

      if (menuItem.subMenus && menuItem.subMenus.length > 0) {
        menuItem.subMenus.forEach((subMenu) => {
          switch (subMenu.id) {
            case 3:
              subMenu.enabled = profiles.includes(2) || profiles.includes(13);
              break;
            case 4:
              subMenu.enabled = profiles.includes(6);
              break;
            case 5:
              subMenu.enabled = profiles.includes(4);
              break;
            case 10:
              subMenu.enabled = profiles.includes(3);
              break;
            case 11:
              subMenu.enabled = profiles.includes(5) || profiles.includes(12);
              break;
            case 14:
              subMenu.enabled = profiles.includes(99);
              subMenu.hidden = !profiles.includes(99);
              break;
            case 22:
              subMenu.enabled = profiles.includes(99);
              subMenu.hidden = !profiles.includes(99);
              break;
            default:
              break;
          }

          if (subMenu.subMenus && subMenu.subMenus.length > 0) {
            subMenu.subMenus.forEach((subSubMenu) => {
              switch (subSubMenu.id) {
                case 17:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                case 18:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                case 19:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                case 20:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                case 21:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                case 23:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                case 24:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                case 25:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                case 26:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                case 29:
                  subSubMenu.enabled = profiles.includes(99);
                  subSubMenu.hidden = !profiles.includes(99);
                  break;
                default:
                  break;
              }
            });
          }
        });
      }
    });
  },

  setActive(state, item) {
    if (item.level === 1) {
      state.menuItems.forEach((menuItem) => {
        if (menuItem.id === item.id) {
          menuItem.active = true;
          state.currentActive = menuItem.id;
        } else {
          menuItem.active = false;
          if (menuItem.subMenus) {
            menuItem.subMenus.forEach((subMenu) => {
              subMenu.active = false;
              if (subMenu.subMenus) {
                subMenu.subMenus.forEach((subSubMenu) => {
                  subSubMenu.active = false;
                });
              }
            });
          }
        }
      });
    } else if (item.level === 2) {
      state.menuItems.forEach((menuItem) => {
        if (menuItem.id === item.parent) {
          menuItem.active = true;
        } else {
          menuItem.active = false;
        }
        if (menuItem.subMenus) {
          menuItem.subMenus.forEach((subMenu) => {
            if (subMenu.id === item.id) {
              subMenu.active = true;
              state.currentActive = subMenu.id;
            } else {
              subMenu.active = false;
            }
          });
        }
      });
    } else if (item.level === 3) {
      state.menuItems.forEach((menuItem) => {
        menuItem.active = false;
        if (menuItem.subMenus) {
          menuItem.subMenus.forEach((subMenu) => {
            if (subMenu.id === item.parent) {
              subMenu.active = true;
              if (subMenu.parent === menuItem.id) {
                menuItem.active = true;
              } else {
                menuItem.active = false;
              }
            } else {
              subMenu.active = false;
            }
            if (subMenu.subMenus) {
              subMenu.subMenus.forEach((subSubMenu) => {
                if (subSubMenu.id === item.id) {
                  subSubMenu.active = true;
                  state.currentActive = subSubMenu.id;
                } else {
                  subSubMenu.active = false;
                }
              });
            }
          });
        }
      });
    }
  },

  setFirst(state, status) {
    state.firstLevel = status;
  },

  setItemClose(state, item) {
    if (item.level === 1) {
      state.menuItems.forEach((menuItem) => {
        if (menuItem.id === item.id) {
          menuItem.open = false;
        }
      });
    } else if (item.level === 2) {
      state.menuItems.forEach((menuItem) => {
        if (menuItem.id === item.id) {
          menuItem.open = false;
        }
        if (menuItem.subMenus) {
          menuItem.subMenus.forEach((subMenu) => {
            if (subMenu.id === item.id) {
              subMenu.open = false;
            }
          });
        }
      });
    }
  },

  setItemOpen(state, item) {
    if (item.level === 1) {
      state.menuItems.forEach((menuItem) => {
        if (menuItem.id === item.id) {
          menuItem.open = true;
        }
      });
    } else if (item.level === 2) {
      state.menuItems.forEach((menuItem) => {
        if (menuItem.id === item.id) {
          menuItem.open = true;
        }
        if (menuItem.subMenus) {
          menuItem.subMenus.forEach((subMenu) => {
            if (subMenu.id === item.id) {
              subMenu.open = true;
            }
          });
        }
      });
    }
  },

  setBiggerThanScreen(state, status) {
    state.biggerThanScreen = status;
  },
};
