import { render, staticRenderFns } from "./NavBody.vue?vue&type=template&id=16c3f804&"
import script from "./NavBody.vue?vue&type=script&lang=js&"
export * from "./NavBody.vue?vue&type=script&lang=js&"
import style0 from "./NavBody.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavItem: require('/home/runner/work/data-olx/data-olx/components/NavMenu/NavItem.vue').default})
